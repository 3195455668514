import { Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomepageAds,
  getHomepageLogo,
  getTrendingChip,
} from "../../../redux/actions/job_action";
import HomepageDropResume from "./HomepageDropResume/HomepageDropResume";
import HomepageHeader from "./HomepageHeader/HomepageHeader";
import HomepageLogo from "./HomepageLogo/HomepageLogo";
import HomepageMobileLogo from "./HomepageMobileLogo/HomepageMobileLogo";
import HomepageSearchBar from "./HomepageSearchBar/HomepageSearchBar";
import {
  ArrowIcon,
  BlankContainer,
  DesktopContainer,
  LogoContainer,
  MobileContainer,
  MobileLogoContainer,
  OuterContainer,
  SearchContainer,
  TabletImageContainer,
  WPTContainer,
  WPTTypography,
} from "./styles";

export default function HomepageSearch(props) {
  const { userResume, setUserResume, inputFileRef } = props;

  const dispatch = useDispatch();
  const homepageLogo = useSelector((state) => state?.jobs?.homepageLogo);

  const firstHomepageLogo = homepageLogo.slice(0, 4);
  const secondHomepageLogo = homepageLogo.slice(4, 9);

  const trendingChip = useSelector((state) => state?.jobs?.trendingChip);

  useEffect(() => {
    dispatch(getHomepageLogo());
    dispatch(getTrendingChip());
    dispatch(getHomepageAds());
  }, []);

  // Pulsing sequence

  const screenDesktop = useMediaQuery("(min-width:1280px)");
  const screenTablet = useMediaQuery("(min-width:768px)");

  const imagePlaceholder = [
    "/images/homepage-logo-placeholder/icon_internship.webp",
    "/images/homepage-logo-placeholder/icon_court.webp",
    "/images/homepage-logo-placeholder/icon_shop.webp",
    "/images/homepage-logo-placeholder/icon_puzzle.webp",
    "/images/homepage-logo-placeholder/icon_hiredly_logo.webp",
    "/images/homepage-logo-placeholder/icon_code.webp",
    "/images/homepage-logo-placeholder/icon_fire.webp",
    "/images/homepage-logo-placeholder/icon_company.webp",
    "/images/homepage-logo-placeholder/icon_medical.webp",
  ];

  const workPersonaTest = [
    {
      title: "Hiredly's Work Personality Test",
      url: "https://hiredly.typeform.com/to/K4idZTDK?utm_medium=shortened_link&utm_source=test&utm_campaign=work_persona_2",
    },
  ];

  const WPTText = (
    <>
      find out what{" "}
      <b>
        <u>chaotic office item</u>
      </b>{" "}
      you are by taking our <b>work personality test!</b>
    </>
  );

  return (
    <Fragment>
      {screenDesktop ? (
        <OuterContainer container justifyContent="center" alignItems="center">
          <Fade in={true} timeout={400}>
            <DesktopContainer
              container
              direction="row"
              columnGap="50px"
              flexWrap="nowrap"
              alignItems="center"
            >
              {homepageLogo && (
                <>
                  <WPTContainer>
                    <WPTTypography>{WPTText}</WPTTypography>
                  </WPTContainer>
                  <ArrowIcon />
                </>
              )}
              <LogoContainer
                container
                item
                xs={3}
                lg={3}
                direction="row"
                isFirst
                columnGap="20px"
              >
                <HomepageLogo
                  workPersonaTest={workPersonaTest[0]}
                  imageList={firstHomepageLogo}
                />
              </LogoContainer>

              {/* For custom cursor when hovering onto company logo */}
              <SearchContainer
                // border={"1px solid red"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                direction="column"
                item
                rowGap="16px"
                xs={6}
                lg={5.1}
              >
                <HomepageHeader />
                <HomepageSearchBar />
                <HomepageDropResume
                  inputFileRef={inputFileRef}
                  triggerToast={props.triggerToast}
                  userResume={userResume}
                  setUserResume={setUserResume}
                />
                {/* <HomepageTrending /> */}
              </SearchContainer>

              {/* Show second logo container if in desktop view */}
              {screenDesktop && (
                <LogoContainer
                  container
                  isSecond
                  item
                  xs={3}
                  lg={3}
                  columnGap="20px"
                >
                  {/* Search Bar */}
                  <HomepageLogo isSecond imageList={secondHomepageLogo} />
                </LogoContainer>
              )}
            </DesktopContainer>
          </Fade>
        </OuterContainer>
      ) : (
        <Fade in={true} timeout={400}>
          <MobileContainer
            container
            direction="column"
            sx={{
              minHeight:
                Array.isArray(homepageLogo) &&
                homepageLogo.length < 1 &&
                "100% !important",

              paddingTop:
                Array.isArray(homepageLogo) &&
                homepageLogo.length < 1 &&
                "50px !important",
            }}
          >
            {screenTablet ? (
              <TabletImageContainer>
                <LogoContainer
                  container
                  item
                  direction="row"
                  columnGap="20px"
                  xs={12}
                >
                  {homepageLogo && (
                    <>
                      <WPTContainer>
                        <WPTTypography>{WPTText}</WPTTypography>
                      </WPTContainer>
                      <ArrowIcon />
                    </>
                  )}
                  <HomepageLogo
                    imageList={homepageLogo}
                    imagePlaceholder={imagePlaceholder}
                    screenTablet={screenTablet}
                    workPersonaTest={workPersonaTest[0]}
                  />
                </LogoContainer>
              </TabletImageContainer>
            ) : (
              <BlankContainer container>
                <MobileLogoContainer
                  margin="auto"
                  justifyContent="center"
                  alignItems="center"
                  display={"grid"}
                  gap="8px"
                  gridTemplateColumns="repeat(3, auto)"
                  xs={12}
                >
                  {Array.from({ length: 9 }).map((_, index) => {
                    return (
                      <HomepageMobileLogo
                        key={index}
                        id={index}
                        imageList={homepageLogo[index]}
                        imagePlaceholder={imagePlaceholder}
                        workPersonaTest={index === 4 && workPersonaTest[0]}
                      />
                    );
                  })}
                </MobileLogoContainer>
              </BlankContainer>
            )}

            <SearchContainer container item>
              <Grid height={"100%"} maxWidth="520px">
                <Grid>
                  <HomepageHeader />
                  {/* <HomepageAnimatedHeader /> */}
                  <HomepageSearchBar />
                </Grid>
                <HomepageDropResume
                  inputFileRef={inputFileRef}
                  triggerToast={props.triggerToast}
                  userResume={userResume}
                  setUserResume={setUserResume}
                />
              </Grid>
              {/* <HomepageTrending /> */}
            </SearchContainer>
          </MobileContainer>
        </Fade>
      )}
    </Fragment>
  );
}
