import Clear from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import RecentSearches from "recent-searches";
import { bindActionCreators } from "redux";
import {
  setItem,
  updateRecentSearches,
} from "../../../helpers/data_management";
import {
  clearSearchSuggestions,
  fetchSearchSuggestions,
  getTrendingJobKeywords,
  updateJobSearchKeyword,
} from "../../../redux/actions/job_action";
import { updateShowMobileSearch } from "../../../redux/actions/navbar_action";
import { store } from "../../../redux/stores/store";
import Button from "../../shared/SharedButton/SharedButton";
import SharedSpinner from "../../shared/SharedSpinner/SharedSpinner";
import {
  ChipStyled,
  ChipsContainer,
  ClearIconButtonStyled,
  CloseIconContainer,
  CloseIconStyled,
  DeleteIconStyled,
  IconButtonStyled,
  IconTextContainer,
  InputBaseStyled,
  PaperStyled,
  RecentSearchTermContainer,
  SearchBarStyled,
  SearchIconStyled,
  SearchSuggestionContainer,
  SearchTermStyled,
  SearchesContainer,
  SubtitleStyled,
  SuggestionsContainer,
  TimeIconStyled,
  TitleStyled,
} from "./styles";

function MobileHomepageSearch(props) {
  const router = useRouter();
  const [search, setSearch] = useState("");
  const [recentSearches, setRecentSearches] = useState([]);
  const [searchingJobs, setSearchingJobs] = useState(false);
  const [searchBarFocus, setSearchBarFocus] = useState(false);

  const dispatch = useDispatch();

  const searches = new RecentSearches({
    namespace: "recent-searches",
    ranking: "TIME",
    limit: 5,
  });

  useEffect(() => {
    setRecentSearches(searches.getRecentSearches("recent-searches"));

    if (props.trendingJobKeywords.length == 0) {
      let params = { limit: 10 };
      props.getTrendingJobKeywords(params);
    }

    if (props.jobSearchKeyword) {
      let params = {
        keyword: props.jobSearchKeyword,
      };
      props.fetchSearchSuggestions(params);
    }

    return () => {
      setRecentSearches(searches.getRecentSearches("recent-searches"));
    };
  }, []);

  const onSearchBarClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const searchOnClickListener = (e) => {
      if (event !== e) {
        setSearchBarFocus(false);
        window.removeEventListener("click", searchOnClickListener);
      }
    };

    if (!searchBarFocus) {
      setSearchBarFocus(true);
      window.addEventListener("click", searchOnClickListener);
    }
  };

  const onSearchUpdate = (event) => {
    setSearch(event.target.value);

    if (event.target.value.length < 2) {
      return;
    } else {
      setTimeout(() => {
        let params = {
          keyword: event.target.value,
        };
        props.fetchSearchSuggestions(params);
      }, 100);
    }
  };

  const handleSearchButtonClicked = () => {
    setSearchingJobs(true);
    updateRecentSearches(search);

    if (store.getState().jobs.fromMobileNavbar) {
      store.getState().navbar.fromNavbarSearch = true;
      store.getState().jobs.fromLandingPage = false;
      store.getState().jobs.jobListFilter["keyword"] = search;
    } else {
      store.getState().jobs.fromLandingPage = true;
      store.getState().navbar.fromNavbarSearch = false;
      store.getState().jobs.jobListFilterLanding["keyword"] = search;
    }

    store.getState().jobs.jobListFilterLanding["keyword"] = search;
    props.updateShowMobileSearch(false);

    dispatch(updateJobSearchKeyword({ keyword: search ?? "" }));
    router.push("/jobs");
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchButtonClicked();
    }
  };

  const handleSearchTermClicked = (searchTerm, type) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    updateRecentSearches(searchTerm);
    setSearch(searchTerm);

    // GA tracking custom event
    if (store.getState().jobs.fromMobileNavbar) {
      if (type == "recent-search") {
        store.getState().jobs.fromNavbarTrendingSearches = false;
        store.getState().jobs.fromNavbarRecentSearches = true;
        store.getState().jobs.fromNavbarSuggestedSearches = false;
      } else if (type == "trending-search") {
        store.getState().jobs.fromNavbarTrendingSearches = true;
        store.getState().jobs.fromNavbarRecentSearches = false;
        store.getState().jobs.fromNavbarSuggestedSearches = false;
      } else if (type == "suggested-search") {
        store.getState().jobs.fromNavbarTrendingSearches = false;
        store.getState().jobs.fromNavbarRecentSearches = false;
        store.getState().jobs.fromNavbarSuggestedSearches = true;
      }
    } else {
      if (type == "recent-search") {
        store.getState().jobs.fromHomepageSearchbarTrendingSearches = false;
        store.getState().jobs.fromHomepageRecentSearches = true;
        store.getState().jobs.fromHomepageSuggestedSearches = false;
      } else if (type == "trending-search") {
        store.getState().jobs.fromHomepageSearchbarTrendingSearches = true;
        store.getState().jobs.fromHomepageRecentSearches = false;
        store.getState().jobs.fromHomepageSuggestedSearches = false;
      } else if (type == "suggested-search") {
        store.getState().jobs.fromHomepageSearchbarTrendingSearches = false;
        store.getState().jobs.fromHomepageRecentSearches = false;
        store.getState().jobs.fromHomepageSuggestedSearches = true;
      }
    }

    if (store.getState().jobs.fromMobileNavbar) {
      store.getState().jobs.jobListFilter["keyword"] = searchTerm;
      store.getState().navbar.fromNavbarSearch = true;
      store.getState().jobs.fromLandingPage = false;
    } else {
      store.getState().jobs.jobListFilterLanding["keyword"] = searchTerm;
      store.getState().jobs.fromLandingPage = true;
      store.getState().navbar.fromNavbarSearch = false;
    }

    props.updateShowMobileSearch(false);
    props.clearSearchSuggestions();
    router.push("/jobs");
  };

  const handleDeleteClicked = (query) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    let updatedSearches = recentSearches.filter(
      (recentSearch) => recentSearch.query !== query
    );
    setRecentSearches(updatedSearches);
    setItem("recent-searches", JSON.stringify(updatedSearches));
  };

  const handleClose = () => {
    props.updateShowMobileSearch(false);
    store.getState().jobs.fromHomepageSearchbarTrendingSearches = false;
    store.getState().jobs.fromHomepageRecentSearches = false;
  };

  const boldMatchingText = (suggestion, searchInput) => {
    const n = suggestion.toLowerCase();
    const q = searchInput.toLowerCase();
    const x = n.indexOf(q);
    // If suggestion has no matching characters, return suggestion
    if (!q || x === -1) {
      return (
        <SearchSuggestionContainer
          onClick={handleSearchTermClicked(suggestion, "suggested-search")}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: suggestion,
            }}
          />
        </SearchSuggestionContainer>
      );
    }
    // Else bold characters that match search input
    const l = q.length;
    const boldedSearch =
      suggestion.substr(0, x) +
      "<b>" +
      suggestion.substr(x, l) +
      "</b>" +
      suggestion.substr(x + l);

    return (
      <SearchSuggestionContainer
        onClick={handleSearchTermClicked(suggestion, "suggested-search")}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: boldedSearch,
          }}
        />
      </SearchSuggestionContainer>
    );
  };

  const handleClearSearchInput = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSearch("");
    store.getState().jobs.jobListFilterLanding["keyword"] = "";
    store.getState().jobs.jobListFilter["keyword"] = "";
  };

  return (
    <PaperStyled open={props.showMobileSearch}>
      <CloseIconContainer>
        <IconButtonStyled onClick={handleClose}>
          <CloseIconStyled />
        </IconButtonStyled>
      </CloseIconContainer>
      <TitleStyled>Discover Your Dream Job.</TitleStyled>
      <SearchBarStyled>
        <InputBaseStyled
          placeholder={"Search by job titles, companies or skills"}
          onChange={onSearchUpdate}
          onKeyDown={onKeyDown}
          onClick={onSearchBarClicked}
        />
        {search.length > 2 && searchBarFocus && (
          <ClearIconButtonStyled onClick={handleClearSearchInput}>
            <Clear />
          </ClearIconButtonStyled>
        )}
      </SearchBarStyled>
      <Button
        button_type={"SolidPurple"}
        desktop_width={"100%"}
        font_size={"caption"}
        margin={"15px 0"}
        onClick={handleSearchButtonClicked}
      >
        {searchingJobs ? (
          <SharedSpinner width={28} height={28} type={"white"} />
        ) : (
          "Search"
        )}
      </Button>

      {search.length > 2 && searchBarFocus ? (
        <Fragment>
          {props.searchData?.length > 0 && (
            <SuggestionsContainer>
              {props.searchData.map((data, index) => {
                return (
                  <div key={index}>{boldMatchingText(data.name, search)}</div>
                );
              })}
            </SuggestionsContainer>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {props.trendingJobKeywords.length > 0 && (
            <SearchesContainer>
              <SubtitleStyled>Trending Searches</SubtitleStyled>
              <ChipsContainer>
                {props.trendingJobKeywords.slice(0, 6).map((keyword, index) => {
                  return (
                    <ChipStyled
                      icon={<SearchIconStyled />}
                      label={keyword}
                      key={index}
                      onClick={handleSearchTermClicked(
                        keyword,
                        "trending-search"
                      )}
                    />
                  );
                })}
              </ChipsContainer>
            </SearchesContainer>
          )}

          {recentSearches.length > 0 && (
            <SearchesContainer>
              <SubtitleStyled>Recent Searches</SubtitleStyled>
              {recentSearches.map((recentSearch, index) => {
                return (
                  <RecentSearchTermContainer>
                    <IconTextContainer
                      onClick={handleSearchTermClicked(
                        recentSearch.query,
                        "recent-search"
                      )}
                    >
                      <TimeIconStyled />
                      <SearchTermStyled>{recentSearch.query}</SearchTermStyled>
                    </IconTextContainer>
                    <IconButton
                      onClick={handleDeleteClicked(recentSearch.query)}
                    >
                      <DeleteIconStyled />
                    </IconButton>
                  </RecentSearchTermContainer>
                );
              })}
            </SearchesContainer>
          )}
        </Fragment>
      )}
    </PaperStyled>
  );
}

const mapStateToProps = (state) => {
  return {
    showMobileSearch: state.navbar.showMobileSearch,
    trendingJobKeywords: state.jobs.trendingJobKeywords,
    jobListFilter: state.jobs.jobListFilter,
    searchData: state.jobs.searchData,
    jobListFilterLanding: state.jobs.jobListFilterLanding,
    jobSearchKeyword: state.jobs.jobSearchKeyword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateShowMobileSearch: bindActionCreators(
      updateShowMobileSearch,
      dispatch
    ),
    fetchSearchSuggestions: bindActionCreators(
      fetchSearchSuggestions,
      dispatch
    ),
    clearSearchSuggestions: bindActionCreators(
      clearSearchSuggestions,
      dispatch
    ),
    getTrendingJobKeywords: bindActionCreators(
      getTrendingJobKeywords,
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHomepageSearch);
