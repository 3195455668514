import Grid from "@mui/material/Grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../../assets/colors";
import WorkPersonaTestIcon from "../../../../assets/svg/wpt-icon.svg";

// Image Component //
export const ImageStyled = styling("img")({
  width: "100%",
  height: "100%",
  borderRadius: "0.5rem",
  objectFit: "contain",
  border: "1.5px solid #000",
});

// Grid Components //
export const OuterGrid = styling(Grid)({
  height: "auto",
});

export const BlankGrid = styling(Grid)({
  //
});

export const DesktopImageContainer = styling(Grid)({
  width: "auto",
  justifyContent: "center",
  alignItems: "center",
});

export const ImageContainer = styling(Grid)((props) => ({
  backgroundColor: props?.isWorkPersonaTest ? Color.hiredlyPurple : Color.white,
  transitionDuration: "0.5s",
  transform: props.transform,
  height: "98.571px",
  width: "98.571px",
  margin: "11.1px 10.3px 10.3px 11.1px",
  opacity: props.opacity,
  borderRadius: "8px",
  border: props?.isWorkPersonaTest && "1.5px solid #000",
  zIndex: 1,
  right: props.right,
  position: "relative",

  [props.theme.breakpoints.down("lg")]: {
    transitionDuration: "2.5s",
  },

  ":hover": {
    cursor: "none",
    transform: `scale(1.2)`,
  },
}));

export const CustomTooltip = styling(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "20px",
    padding: "5px",
    border: "3px solid black",
    backgroundColor: Color.white,
    color: "black",
    zIndex: 5,
    cursor: "none",
  },
});

export const LinkStyled = styling("a")((props) => ({
  pointerEvents: props["aria-disabled"] ? "none" : "auto",
}));

// Icon Components //
export const WorkPersonaIcon = styling(WorkPersonaTestIcon)((props) => ({}));
